// extracted by mini-css-extract-plugin
export var backgroundAnimation = "graj-w-klasyke-scoreboard-module--backgroundAnimation--f0f08";
export var button = "graj-w-klasyke-scoreboard-module--button--c770a";
export var container = "graj-w-klasyke-scoreboard-module--container--66c1c";
export var empty = "graj-w-klasyke-scoreboard-module--empty--66549";
export var errorBlink = "graj-w-klasyke-scoreboard-module--error-blink--50634";
export var innerList = "graj-w-klasyke-scoreboard-module--inner-list--2e69f";
export var item = "graj-w-klasyke-scoreboard-module--item--07299";
export var list = "graj-w-klasyke-scoreboard-module--list--8756c";
export var name = "graj-w-klasyke-scoreboard-module--name--bee05";
export var open = "graj-w-klasyke-scoreboard-module--open--8af4a";
export var title = "graj-w-klasyke-scoreboard-module--title--6d098";
export var top = "graj-w-klasyke-scoreboard-module--top--d10be";