import { useMemo, useState, useEffect, useRef } from 'react';

import {
    BOTTLE_ICONS,
    CELL_COUNT_X,
    CELL_COUNT_Y,
    INITIAL_DIRECTION,
    INITIAL_MODE,
    INITIAL_SNAKE,
    INITIAL_SPEED,
    KEY_TO_DIRECTION,
    MAX_POINTS,
    MAX_SPEED,
    NOT_ALLOWED_DIRECTION_CHANGES,
    SPEED_INCREMENT,
    TOUCH_EVENTS,
    TOUCH_SENSITIVITY_FACTOR,
} from './consts';
import { TFoodPosition, TPosition, TSnakeDirection, TSnakeMode } from './snake-game.model';
import { getCellString, getCellStringsArray } from './utils';

export const useSnakeEngine = () => {
    const [isStarted, setIsStarted] = useState(false);
    const [isGameOver, setIsGameOver] = useState(false);
    const [, setRenderCount] = useState(0);
    const [points, setPoints] = useState(0);
    const [speed, setSpeed] = useState(INITIAL_SPEED);

    const boardCells = useMemo(() => {
        return getBoardArray(CELL_COUNT_X, CELL_COUNT_Y);
    }, []);

    const boardRef = useRef<HTMLDivElement | null>(null);
    const touchRef = useRef<TPosition | null>(null);
    const intervalRef = useRef<NodeJS.Timer | null>(null);
    const dotsRef = useRef<TPosition[]>(INITIAL_SNAKE);
    const dotsStringsRef = useRef<string[]>(getCellStringsArray(INITIAL_SNAKE));
    const prevDirectionRef = useRef<TSnakeDirection>(INITIAL_DIRECTION);
    const directionRef = useRef<TSnakeDirection>(INITIAL_DIRECTION);
    const userDirectionRef = useRef<TSnakeDirection[]>([]);
    const foodRef = useRef<TFoodPosition>(
        getRandomFood(dotsRef.current, boardCells, BOTTLE_ICONS.length)
    );
    const foodAreaRef = useRef<TPosition[]>(getFoodArea(foodRef.current));
    const foodAreaStringsRef = useRef<string[]>(getCellStringsArray(foodAreaRef.current));
    const snakeModeRef = useRef<TSnakeMode>(INITIAL_MODE);
    const isWinRef = useRef(false);

    const reset = () => {
        setIsStarted(false);
        setIsGameOver(false);
        setSpeed(INITIAL_SPEED);
        setPoints(0);
        touchRef.current = null;
        dotsRef.current = INITIAL_SNAKE;
        dotsStringsRef.current = getCellStringsArray(INITIAL_SNAKE);
        prevDirectionRef.current = INITIAL_DIRECTION;
        directionRef.current = INITIAL_DIRECTION;
        userDirectionRef.current = [];
        foodRef.current = getRandomFood(dotsRef.current, boardCells, BOTTLE_ICONS.length);
        foodAreaRef.current = getFoodArea(foodRef.current);
        foodAreaStringsRef.current = getCellStringsArray(foodAreaRef.current);
        snakeModeRef.current = INITIAL_MODE;
        isWinRef.current = false;
    };

    const moveSnake = () => {
        prevDirectionRef.current = directionRef.current;
        const userDirection = userDirectionRef.current.shift();
        directionRef.current = userDirection || directionRef.current;
        const direction = directionRef.current;
        const food = foodRef.current;
        const head = dotsRef.current[dotsRef.current.length - 1];
        const dots = [...dotsRef.current];
        if (direction === 'left') {
            dots.push([head[0] - 1, head[1]]);
        }
        if (direction === 'right') {
            dots.push([head[0] + 1, head[1]]);
        }
        if (direction === 'up') {
            dots.push([head[0], head[1] - 1]);
        }
        if (direction === 'down') {
            dots.push([head[0], head[1] + 1]);
        }
        const newHead = dots[dots.length - 1];
        if (checkIfSnakeAte(newHead, food)) {
            isWinRef.current = dots.length - INITIAL_SNAKE.length === MAX_POINTS;
            if (!isWinRef.current) {
                foodRef.current = getRandomFood(dots, boardCells, BOTTLE_ICONS.length);
                foodAreaRef.current = getFoodArea(foodRef.current);
                foodAreaStringsRef.current = getCellStringsArray(foodAreaRef.current);
                setSpeed((prev) => {
                    const newSpeed = prev - SPEED_INCREMENT;
                    return newSpeed > MAX_SPEED ? newSpeed : MAX_SPEED;
                });
            } else {
                setIsGameOver(true);
            }
            setPoints((prev) => prev + 1);
        } else if (checkIfSnakeCollided(dots, CELL_COUNT_X, CELL_COUNT_Y)) {
            snakeModeRef.current = 'collided';
            setIsGameOver(true);
            return;
        } else {
            dots.shift();
            if (foodAreaStringsRef.current.includes(getCellString(newHead))) {
                snakeModeRef.current = 'eat';
            } else {
                snakeModeRef.current = 'normal';
            }
        }
        dotsRef.current = dots;
        dotsStringsRef.current = getCellStringsArray(dots);
        setRenderCount((prev) => prev + 1);
    };

    useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            if (snakeModeRef.current === 'collided' || isWinRef.current) return;
            const direction =
                userDirectionRef.current[userDirectionRef.current.length - 1] ||
                directionRef.current;
            const newDirection = KEY_TO_DIRECTION[event.key];
            if (!newDirection) return;
            event.preventDefault();
            if (direction && NOT_ALLOWED_DIRECTION_CHANGES.includes(`${direction},${newDirection}`))
                return;
            if (newDirection !== direction) {
                userDirectionRef.current = [...userDirectionRef.current, newDirection];
            }
            if (!isStarted) {
                setIsStarted(true);
            }
        };
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        };
    }, [isStarted]);

    useEffect(() => {
        const boardEl = boardRef.current;
        if (!boardEl) return;
        const handleTouch = (event: TouchEvent) => {
            if (snakeModeRef.current === 'collided' || isWinRef.current) return;
            const touch = event.touches[0];
            if (event.type === 'touchstart') {
                touchRef.current = [touch.clientX, touch.clientY];
            }
            if (event.type === 'touchmove' && touchRef.current) {
                const deltaX = touch.clientX - touchRef.current[0];
                const deltaY = touch.clientY - touchRef.current[1];
                if (
                    Math.abs(deltaX) < TOUCH_SENSITIVITY_FACTOR &&
                    Math.abs(deltaY) < TOUCH_SENSITIVITY_FACTOR
                )
                    return;
                touchRef.current = [touch.clientX, touch.clientY];
                const direction =
                    userDirectionRef.current[userDirectionRef.current.length - 1] ||
                    directionRef.current;
                let newDirection: TSnakeDirection;
                if (Math.abs(deltaY) > Math.abs(deltaX)) {
                    newDirection = deltaY > 0 ? 'down' : 'up';
                } else {
                    newDirection = deltaX > 0 ? 'right' : 'left';
                }
                if (
                    !newDirection ||
                    !direction ||
                    NOT_ALLOWED_DIRECTION_CHANGES.includes(`${direction},${newDirection}`)
                )
                    return;
                if (newDirection !== direction) {
                    userDirectionRef.current = [...userDirectionRef.current, newDirection];
                }
                if (!isStarted) {
                    setIsStarted(true);
                }
            }
            if (event.type === 'touchend') {
                touchRef.current = null;
            }
        };
        TOUCH_EVENTS.forEach((eventName) => {
            boardEl.addEventListener(eventName, handleTouch);
        });
        return () => {
            if (boardEl) {
                TOUCH_EVENTS.forEach((eventName) => {
                    boardEl.removeEventListener(eventName, handleTouch);
                });
            }
        };
    }, [isStarted]);

    useEffect(() => {
        if (!isStarted) return;
        if (isGameOver && intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setIsStarted(false);
        } else if (!isGameOver) {
            intervalRef.current = setInterval(moveSnake, speed);
        }
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [isStarted, isGameOver, speed]);

    return {
        boardRef,
        boardCells,
        dotsStringsRef,
        foodRef,
        points,
        snakeModeRef,
        dotsRef,
        prevDirectionRef,
        directionRef,
        speed,
        isGameOver,
        reset,
        isStarted,
    };
};

function getBoardArray(x: number, y: number): TPosition[] {
    const arr = [];
    for (let i = 0; i < y; i++) {
        for (let j = 0; j < x; j++) {
            arr.push([j, i] as TPosition);
        }
    }
    return arr;
}

function checkIfSnakeAte(head: TPosition, food: TFoodPosition) {
    return head[0] === food[0] && head[1] === food[1];
}

function getFoodArea(food: TFoodPosition): TPosition[] {
    return [
        [food[0], food[1]],
        [food[0] - 1, food[1] - 1],
        [food[0], food[1] - 1],
        [food[0] + 1, food[1] - 1],
        [food[0] - 1, food[1]],
        [food[0] + 1, food[1]],
        [food[0] - 1, food[1] + 1],
        [food[0], food[1] + 1],
        [food[0] + 1, food[1] + 1],
    ];
}

function checkIfSnakeCollided(dots: TPosition[], x: number, y: number) {
    const head = dots[dots.length - 1];
    const body = [...dots];
    body.splice(-1);
    const bodyStringArr = getCellStringsArray(body);
    return (
        bodyStringArr.includes(getCellString(head)) ||
        head[0] < 0 ||
        head[0] === x ||
        head[1] < 0 ||
        head[1] === y
    );
}

function getRandomFood(dots: TPosition[], board: TPosition[], bottlesCount: number): TFoodPosition {
    const dotsStringArr = getCellStringsArray(dots);
    const boardStringArr = getCellStringsArray(board);
    const filteredBoard = boardStringArr.filter((boardDot) => !dotsStringArr.includes(boardDot));
    const randomIndex = getRandomNumber(0, filteredBoard.length - 1);
    const bottleIndex = getRandomNumber(0, bottlesCount - 1);
    const randomPosition = filteredBoard[randomIndex].split(',').map((num) => Number(num));
    return [...randomPosition, bottleIndex] as TFoodPosition;
}

function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
