// extracted by mini-css-extract-plugin
export var actions = "snake-game-module--actions--12043";
export var adultIcon = "snake-game-module--adult-icon--76c72";
export var backgroundAnimation = "snake-game-module--backgroundAnimation--b9e29";
export var bar = "snake-game-module--bar--948d6";
export var board = "snake-game-module--board--ce18c";
export var boardCell = "snake-game-module--board-cell--d3f93";
export var closeButton = "snake-game-module--close-button--067f9";
export var container = "snake-game-module--container--5b784";
export var controlIcon = "snake-game-module--control-icon--c0399";
export var errorBlink = "snake-game-module--error-blink--92cf3";
export var evenRow = "snake-game-module--even-row--fc85a";
export var game = "snake-game-module--game--42444";
export var gameOuter = "snake-game-module--game-outer--402ae";
export var logoBox = "snake-game-module--logo-box--0b207";
export var modal = "snake-game-module--modal--6a9da";
export var oddRow = "snake-game-module--odd-row--2f996";
export var pointBox = "snake-game-module--point-box--e85fb";
export var scaled = "snake-game-module--scaled--94b2d";
export var summary = "snake-game-module--summary--34eb3";