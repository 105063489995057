import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { container, result, info, circle, button, icon, image } from './game-summary.module.scss';
import SnakeResultIcon from '../assets/images/svg/snake-result.svg';
import { ImageDataLike } from '../models/image-data-like.model';

interface IGameSummaryQueryResult {
    bottlesImg: ImageDataLike;
}

interface IGameSummaryProps {
    className?: string;
    points: number;
    onPlay?(): void;
    onQuestion?(): void;
}

const GameSummary: React.FC<IGameSummaryProps> = ({
    className = '',
    points,
    onPlay,
    onQuestion,
}) => {
    const { bottlesImg } = useStaticQuery<IGameSummaryQueryResult>(query);
    const bottlesImgData = getImage(bottlesImg);

    return (
        <div className={`${container} ${className}`}>
            <div className={result}>
                <div className={info}>
                    <div className={circle}>
                        <div className={image}>
                            {bottlesImgData && <GatsbyImage image={bottlesImgData} alt="" />}
                        </div>
                    </div>
                    <div className={circle}>{points}</div>
                </div>
                <SnakeResultIcon className={icon} />
            </div>
            <button className={button} onClick={onPlay}>
                Gram ponownie
            </button>
            <button className={button} onClick={onQuestion}>
                Pytanie konkursowe
            </button>
        </div>
    );
};

const query = graphql`
    query {
        bottlesImg: file(relativePath: { eq: "snake-bottles-result.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
    }
`;

export default GameSummary;
