// extracted by mini-css-extract-plugin
export var backgroundAnimation = "main-module--backgroundAnimation--20a57";
export var banner = "main-module--banner--06537";
export var container = "main-module--container--b44f1";
export var desktop = "main-module--desktop--c6142";
export var errorBlink = "main-module--error-blink--c5afd";
export var form = "main-module--form--425b4";
export var game = "main-module--game--81af7";
export var img = "main-module--img--0789f";
export var isExpired = "main-module--is-expired--812bc";
export var knight = "main-module--knight--253a4";
export var layout = "main-module--layout--9e10f";
export var mobile = "main-module--mobile--535e3";
export var peelLeft = "main-module--peelLeft--23a16";
export var peelRight = "main-module--peelRight--68311";
export var person = "main-module--person--7b51a";
export var prize = "main-module--prize--ffc97";
export var scoreboard = "main-module--scoreboard--dd2d2";
export var soplicaGorzka = "main-module--soplicaGorzka--b5ac0";
export var soplicaMint = "main-module--soplicaMint--837bf";
export var soplicaNoble = "main-module--soplicaNoble--b87c4";
export var vinyl = "main-module--vinyl--970c6";