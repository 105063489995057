import * as Yup from 'yup';
import { TFunction } from 'react-i18next';
import { object, string } from 'yup';

export interface IGrajWKlasykeValues {
    email: string;
    nick: string;
    firstname: string;
    lastname: string;
    size: string;
    phone: string;
    phonePrefix: string;
    answer: string;
    score: number;
    files: { content: string; mimeType: string; name: string }[];
    rules1: boolean;
    rules2: boolean;
}

// Tylko cyfry od 0 do 9, równo 9 cyfr
const phoneRegExp = /^[0-9]{9}$/;

export const initialValues: IGrajWKlasykeValues = {
    email: '',
    nick: '',
    firstname: '',
    lastname: '',
    size: '',
    phone: '',
    phonePrefix: '+48',
    answer: '',
    score: 0,
    files: [],
    rules1: false,
    rules2: false,
};

export const GRAJ_W_KLASYKE_MAX_ANSWER_LENGTH = 500;
export const GRAJ_W_KLASYKE_MAX_FILE_SIZE = 26214400;

export function getValidationSchema(t: TFunction<string | string[]>) {
    return Yup.object().shape(
        {
            email: Yup.string().email(t('form.email.error')).required(t('form.required.error')),
            nick: Yup.string().required(t('form.required.error')),
            firstname: Yup.string().required(t('form.required.error')),
            lastname: Yup.string().required(t('form.required.error')),
            size: Yup.string().required(t('form.required.error')),
            phone: Yup.string()
                .matches(phoneRegExp, t('form.phone.error'))
                .required(t('form.required.error')),
            phonePrefix: Yup.string().required(t('form.required.error')),
            answer: Yup.string()
                .max(GRAJ_W_KLASYKE_MAX_ANSWER_LENGTH)
                .when('files', (files, schema) => {
                    return files && files.length > 0
                        ? schema.notRequired()
                        : schema.required(t('form.required.error'));
                }),
            score: Yup.string().required(t('form.required.error')),
            files: Yup.array()
                .of(object({ content: string(), mimeType: string(), name: string() }))
                .nullable()
                .when('answer', (comment, schema) => {
                    return comment && comment.length > 0
                        ? schema.notRequired()
                        : schema.required(t('form.required.error'));
                }),
            rules1: Yup.boolean().oneOf([true], t('form.required.error')),
            rules2: Yup.boolean().oneOf([true], t('form.required.error')),
        },
        [['answer', 'files']]
    );
}
