import React, { useEffect, useRef, useState } from 'react';
import { Form, Formik, Field, ErrorMessage, FormikConfig, FormikProps } from 'formik';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    subtitle,
    textarea,
    answerBox,
    input,
    counter,
    fields,
    checkboxes,
    checkboxLabel,
    checkmark,
    button,
    error as errorClass,
    loading,
    select,
    selectBox,
    selectIcon,
    fileSection,
    text,
    textSmall,
    fileInput,
    fileContainer,
    phoneInput,
    title,
    box,
    snake,
    question,
    submit,
    isActive,
    stepHeader,
    number,
    stepTitle,
    stepSubtitle,
    snakeContainer,
    paper,
    rollerLeft,
    rollerRight,
    person,
    imageContainer,
    game,
    peelRight,
} from './graj-w-klasyke-form.module.scss';

import ChevronIcon from '../../assets/images/svg/angle.svg';

import staticFiles from '../../config/static-files';
import pagesContext from '../../config/pages-context';
import {
    getValidationSchema,
    initialValues,
    IGrajWKlasykeValues,
    GRAJ_W_KLASYKE_MAX_ANSWER_LENGTH,
    GRAJ_W_KLASYKE_MAX_FILE_SIZE,
} from '../../formik/graj-w-klasyke-form.config';
import { grajWKlasykeFormMutation } from '../../api-ssr/graj-w-klasyke';
import { getPathFromSlug } from '../../../plugins/gatsby-plugin-ap-i18next/src/get-path-from-slug';

import FormCheckbox from '../atoms/form-checkbox';
import Button from '../atoms/button';
import FileInput from '../atoms/file-input';
import FormField from '../atoms/form-field';

interface IGrajWKlasykeFormProps {
    className?: string;
    snakePoints: number;
    handleOpenSnakeGame: () => void;
    answerRef?: React.RefObject<HTMLParagraphElement>;
}

const paperImg = '../../assets/images/graj-w-klasyke/paper.png';
const gameImg = '../../assets/images/graj-w-klasyke/game.png';
const peelRightImg = '../../assets/images/graj-w-klasyke/peel-right.png';
const personImg = '../../assets/images/graj-w-klasyke/person.png';
const rollerLeftImg = '../../assets/images/graj-w-klasyke/roller-left.png';
const rollerRightImg = '../../assets/images/graj-w-klasyke/roller-right.png';

const ROUND_ONE_END = new Date('2024-10-20 23:59:59').getTime();
const ROUND_TWO_START = new Date('2024-10-21 00:00:00').getTime();
const ROUND_TWO_END = new Date('2024-10-27 23:59:59').getTime();
const ROUND_THREE_START = new Date('2024-10-28 00:00:00').getTime();
const ROUND_THREE_END = new Date('2024-11-03 23:59:59').getTime();
const ROUND_FOUR_START = new Date('2024-11-04 00:00:00').getTime();

const GrajWKlasykeForm: React.FC<IGrajWKlasykeFormProps> = ({
    className = '',
    snakePoints,
    handleOpenSnakeGame,
    answerRef,
}) => {
    const NOW_TIMESTAMP = new Date().getTime();
    const IS_ROUND_ONE = NOW_TIMESTAMP < ROUND_ONE_END;
    const IS_ROUND_TWO = ROUND_TWO_START <= NOW_TIMESTAMP && NOW_TIMESTAMP < ROUND_TWO_END;
    const IS_ROUND_THREE = ROUND_THREE_START <= NOW_TIMESTAMP && NOW_TIMESTAMP < ROUND_THREE_END;
    const IS_ROUND_FOUR = ROUND_FOUR_START <= NOW_TIMESTAMP;

    const { t, language } = useI18next();
    const [isLoading, setIsLoading] = useState(false);
    const { mutateAsync } = useMutation(grajWKlasykeFormMutation);
    const formRef = useRef<FormikProps<IGrajWKlasykeValues>>(null);

    const handleSubmit: FormikConfig<IGrajWKlasykeValues>['onSubmit'] = async (values) => {
        setIsLoading(true);
        try {
            await mutateAsync(values);
            const resultsUrl = getPathFromSlug(pagesContext.grajWKlasykeThankYou.slug, language);
            navigate(resultsUrl);
        } catch (err: any) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (formRef.current && formRef.current.values.score < snakePoints) {
            formRef.current.setFieldValue('score', snakePoints);
        }
    }, [snakePoints]);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={getValidationSchema(t)}
            innerRef={formRef}
        >
            {({ values }) => {
                const isStepOneComplete =
                    !!values.email &&
                    !!values.nick &&
                    !!values.firstname &&
                    !!values.lastname &&
                    !!values.size &&
                    !!values.phone;
                const isStepTwoComplete = isStepOneComplete && values.rules1 && values.rules2;
                const isStepThreeComplete = isStepTwoComplete && values.score > 0;
                const isStepFourComplete =
                    isStepThreeComplete &&
                    (!!values.answer || (values.files && values.files.length > 0));

                return (
                    <Form className={`${container} ${className} ${isLoading ? loading : ''}`}>
                        <p className={title}>{t('graj.w.klasyke.form.title')}</p>
                        <p className={subtitle}>{t('graj.w.klasyke.form.answer')}</p>
                        <p className={subtitle}>{t('graj.w.klasyke.form.answer.2')}</p>

                        <div className={box}>
                            <StaticImage src={rollerLeftImg} alt="" className={rollerLeft} />
                            <StaticImage src={rollerRightImg} alt="" className={rollerRight} />
                            <div className={stepHeader}>
                                <p className={number}>1</p>
                                <p className={stepTitle}>{t('graj.w.klasyke.form.step.one')}</p>
                            </div>
                            <div className={fields}>
                                <div>
                                    <Field
                                        type="email"
                                        className={input}
                                        name="email"
                                        placeholder={t('graj.w.klasyke.form.email.placeholder')}
                                    />
                                    <ErrorMessage
                                        name="email"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div>
                                    <Field
                                        className={input}
                                        name="nick"
                                        placeholder={t('graj.w.klasyke.form.nick.placeholder')}
                                    />
                                    <ErrorMessage
                                        name="nick"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div>
                                    <Field
                                        className={input}
                                        name="firstname"
                                        placeholder={t('graj.w.klasyke.form.firstname.placeholder')}
                                    />
                                    <ErrorMessage
                                        name="firstname"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div>
                                    <Field
                                        className={input}
                                        name="lastname"
                                        placeholder={t('graj.w.klasyke.form.lastname.placeholder')}
                                    />
                                    <ErrorMessage
                                        name="lastname"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div>
                                    <div className={selectBox}>
                                        <Field
                                            as="select"
                                            className={`${input} ${select}`}
                                            name="size"
                                        >
                                            <option value="" disabled={true}>
                                                {t('graj.w.klasyke.form.size.placeholder')}
                                            </option>
                                            <option value="s">S</option>
                                            <option value="m">M</option>
                                            <option value="l">L</option>
                                            <option value="xl">XL</option>
                                            <option value="xxl">XXL</option>
                                        </Field>
                                        <ChevronIcon className={selectIcon} />
                                    </div>
                                    <ErrorMessage
                                        name="size"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div className={phoneInput}>
                                    <Field
                                        type="phone"
                                        className={`${input}`}
                                        name="phone"
                                        placeholder={t('graj.w.klasyke.form.phone.placeholder')}
                                    />
                                    <ErrorMessage
                                        name="phone"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                            </div>
                            <div className={`${checkboxes} ${isStepOneComplete ? isActive : ''}`}>
                                <div>
                                    <FormCheckbox
                                        textClass={checkboxLabel}
                                        checkmarkClass={checkmark}
                                        name="rules1"
                                        text={t('graj.w.klasyke.form.rules1')}
                                        textLink={t('graj.w.klasyke.form.rules1.textLink')}
                                        link={staticFiles.grajWKlasykeRegulations}
                                        textContinued={t(
                                            'graj.w.klasyke.form.rules1.textContinued'
                                        )}
                                    />
                                    <ErrorMessage
                                        name="rules1"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                                <div>
                                    <FormCheckbox
                                        textClass={checkboxLabel}
                                        checkmarkClass={checkmark}
                                        name="rules2"
                                        text={t('graj.w.klasyke.form.rules2')}
                                    />
                                    <ErrorMessage
                                        name="rules2"
                                        className={errorClass}
                                        component="p"
                                    />
                                </div>
                            </div>
                            <div className={`${snake} ${isStepTwoComplete ? isActive : ''}`}>
                                <div className={snakeContainer}>
                                    <div className={stepHeader}>
                                        <p className={number}>2</p>
                                        <p className={stepTitle}>
                                            {t('graj.w.klasyke.form.step.two')}
                                        </p>
                                    </div>
                                    <div className={imageContainer}>
                                        <StaticImage src={gameImg} alt={''} className={game} />
                                        <StaticImage
                                            src={peelRightImg}
                                            alt={''}
                                            className={peelRight}
                                        />
                                    </div>
                                    <Button className={button} onClick={handleOpenSnakeGame}>
                                        {t('graj.w.klasyke.form.play')}
                                    </Button>
                                </div>
                                <StaticImage src={paperImg} alt="" className={paper} />
                            </div>
                            <div className={`${question} ${isStepThreeComplete ? isActive : ''}`}>
                                <div className={stepHeader}>
                                    <p className={number}>3</p>
                                    <p className={stepTitle}>
                                        {t('graj.w.klasyke.form.step.three')}
                                    </p>
                                </div>
                                <p ref={answerRef} className={stepSubtitle}>
                                    {IS_ROUND_ONE && t('graj.w.klasyke.form.step.three.round.one')}
                                    {IS_ROUND_TWO && t('graj.w.klasyke.form.step.three.round.two')}
                                    {IS_ROUND_THREE &&
                                        t('graj.w.klasyke.form.step.three.round.three')}
                                    {IS_ROUND_FOUR &&
                                        t('graj.w.klasyke.form.step.three.round.four')}
                                </p>
                                <div className={answerBox}>
                                    <Field
                                        as="textarea"
                                        type="textarea"
                                        rows={5}
                                        className={`${textarea} ${input}`}
                                        name="answer"
                                        placeholder={t('graj.w.klasyke.form.answer.placeholder')}
                                        maxLength={GRAJ_W_KLASYKE_MAX_ANSWER_LENGTH}
                                    />
                                    <p className={counter}>
                                        {values.answer.length}/{GRAJ_W_KLASYKE_MAX_ANSWER_LENGTH}
                                    </p>
                                    <ErrorMessage
                                        name="answer"
                                        className={errorClass}
                                        component="p"
                                    />
                                    <StaticImage src={personImg} alt={''} className={person} />
                                </div>
                                <div className={fileSection}>
                                    <p className={text}>{t('graj.w.klasyke.form.file.title')}</p>
                                    <p className={textSmall}>
                                        {t('graj.w.klasyke.form.file.subtitle')}
                                    </p>
                                    <FormField id={'file-field'} className={fileContainer}>
                                        <FileInput
                                            maxFileSize={GRAJ_W_KLASYKE_MAX_FILE_SIZE}
                                            fileTypes={[
                                                'application/pdf',
                                                'image/jpg',
                                                'image/jpeg',
                                                'image/png',
                                                'video/x-msvideo',
                                                'video/mov',
                                                'video/mp4',
                                            ]}
                                            className={fileInput}
                                            name={'files'}
                                            placeholder={t('graj.w.klasyke.form.file.placeholder')}
                                            type={'short'}
                                        />
                                        <ErrorMessage
                                            name="files"
                                            className={errorClass}
                                            component="p"
                                        />
                                    </FormField>
                                </div>
                            </div>
                            <div className={`${submit} ${isStepFourComplete ? isActive : ''}`}>
                                <div className={stepHeader}>
                                    <p className={number}>4</p>
                                    <p className={stepTitle}>
                                        {t('graj.w.klasyke.form.step.four')}
                                    </p>
                                </div>
                                <Button isLoading={isLoading} type="submit" className={button}>
                                    {t('graj.w.klasyke.form.submit')}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default GrajWKlasykeForm;
