// extracted by mini-css-extract-plugin
export var backgroundAnimation = "snake-cell-module--backgroundAnimation--2af4e";
export var eat = "snake-cell-module--eat--45344";
export var errorBlink = "snake-cell-module--error-blink--8bdaa";
export var eyes = "snake-cell-module--eyes--8ab53";
export var eyesCrashIcon = "snake-cell-module--eyes-crash-icon--4b169";
export var eyesIcon = "snake-cell-module--eyes-icon--275c1";
export var headDown = "snake-cell-module--head-down--d0d5c";
export var headLeft = "snake-cell-module--head-left--d666f";
export var headRight = "snake-cell-module--head-right--d205e";
export var headUp = "snake-cell-module--head-up--e5691";
export var mouth = "snake-cell-module--mouth--158f2";
export var mouthIcon = "snake-cell-module--mouth-icon--8bd62";
export var snakeCell = "snake-cell-module--snake-cell--39d22";
export var snakeHead = "snake-cell-module--snake-head--587dc";
export var starPulse = "snake-cell-module--star-pulse--99887";