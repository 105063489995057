import React from 'react';

import {
    eyes,
    snakeHead,
    headLeft,
    headRight,
    headDown,
    headUp,
    eyesIcon,
    mouth,
    mouthIcon,
    snakeCell,
    eyesCrashIcon,
    eat,
} from './snake-cell.module.scss';
import EyesIcon from '../assets/images/svg/snake-eyes.svg';
import EyesCrashIcon from '../assets/images/svg/snake-eyes-crash.svg';
import MouthIcon from '../assets/images/svg/snake-mouth.svg';
import { TPosition, TSnakeDirection, TSnakeMode } from './snake-game.model';
import { END_RG, START_RG } from './consts';

interface ISnakeCellProps {
    className?: string;
    dot: TPosition;
    dots: TPosition[];
    prevDirection: TSnakeDirection;
    direction: TSnakeDirection;
    mode: TSnakeMode;
}

const SnakeCell: React.FC<ISnakeCellProps> = ({
    className = '',
    dots,
    dot,
    direction,
    prevDirection,
    mode,
}) => {
    const localDirection = mode === 'collided' ? prevDirection : direction;
    const index = dots.findIndex(
        (snakeDot) => `${dot[0]},${dot[1]}` === `${snakeDot[0]},${snakeDot[1]}`
    );
    const next = dots[index - 1];
    const prev = dots[index + 1];
    const isHead = index === dots.length - 1;
    const isTail = index === 0;
    const isBody = !!(prev && next);
    const dotDirection = getDotDirection(dot, prev, next, localDirection);
    const gradient = getDotGradient(dots.length, index, dotDirection);

    return (
        <div
            key={`snake-dot-${index}`}
            className={`${className} ${
                isHead ? `${snakeHead} ${headDirectionClasses[localDirection]}` : ''
            } ${isHead && mode === 'eat' ? eat : ''}`}
        >
            <div
                className={snakeCell}
                style={{
                    backgroundImage: gradient,
                    ...(isBody
                        ? {
                              borderRadius: getBodyBorderRadius(dot, prev, next),
                          }
                        : {}),
                    ...(isHead
                        ? {
                              borderRadius: getHeadBorderRadius(localDirection),
                          }
                        : {}),
                    ...(isTail
                        ? {
                              borderRadius: getTailBorderRadius(dot, prev),
                          }
                        : {}),
                }}
            >
                {isHead && (
                    <>
                        <div className={eyes}>
                            {(mode === 'normal' || mode === 'eat') && (
                                <EyesIcon className={eyesIcon} />
                            )}
                            {mode === 'collided' && <EyesCrashIcon className={eyesCrashIcon} />}
                        </div>
                        <div className={mouth}>
                            <MouthIcon className={mouthIcon} />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

const headDirectionClasses: Record<TSnakeDirection, string> = {
    left: headLeft,
    right: headRight,
    down: headDown,
    up: headUp,
};

function getDotGradient(dotsCount: number, index: number, direction: string) {
    const rangeR = END_RG[0] - START_RG[0];
    const rangeFractionR = rangeR / dotsCount;
    const startR = END_RG[0] - rangeFractionR * index;
    const endR = END_RG[0] - rangeFractionR * (index + 1);

    const rangeG = END_RG[1] - START_RG[1];
    const rangeFractionG = rangeG / dotsCount;
    const startG = END_RG[1] - rangeFractionG * index;
    const endG = END_RG[1] - rangeFractionG * (index + 1);

    let gradientDirection = 'to left';
    if (direction === 'left') {
        gradientDirection = 'to right';
    }
    if (direction === 'down') {
        gradientDirection = 'to top';
    }
    if (direction === 'up') {
        gradientDirection = 'to bottom';
    }
    if (direction === 'up-left' || direction === 'left-up') {
        gradientDirection = 'to bottom right';
    }
    if (direction === 'up-right' || direction === 'right-up') {
        gradientDirection = 'to bottom left';
    }
    if (direction === 'left-down' || direction === 'down-left') {
        gradientDirection = 'to right top';
    }
    if (direction === 'right-down' || direction === 'down-right') {
        gradientDirection = 'to left top';
    }

    return `linear-gradient(${gradientDirection}, rgb(${endR},${endG},0), rgb(${startR},${startG},0))`;
}

function getDotDirection(
    dot: TPosition,
    prev: TPosition,
    next: TPosition,
    direction: TSnakeDirection
) {
    if (prev && next) {
        if (prev[0] === dot[0] && next[0] === dot[0]) {
            if (prev[1] > dot[1]) return 'down';
            if (prev[1] < dot[1]) return 'up';
        }
        if (prev[1] === dot[1] && next[1] === dot[1]) {
            if (prev[0] > dot[0]) return 'right';
            if (prev[0] < dot[0]) return 'left';
        }
        if (prev[0] === dot[0] && prev[1] < dot[1]) {
            if (next[0] > dot[0]) return 'up-left';
            if (next[0] < dot[0]) return 'up-right';
        }
        if (prev[0] < dot[0] && prev[1] === dot[1]) {
            if (next[1] > dot[1]) return `left-up`;
            if (next[1] < dot[1]) return `left-down`;
        }
        if (prev[0] > dot[0] && prev[1] === dot[1]) {
            if (next[1] > dot[1]) return `right-up`;
            if (next[1] < dot[1]) return `right-down`;
        }
        if (prev[0] === dot[0] && prev[1] > dot[1]) {
            if (next[0] > dot[0]) return `down-left`;
            if (next[0] < dot[0]) return `down-right`;
        }
    }
    if (!next && prev) {
        if (prev[0] < dot[0]) return 'left';
        if (prev[0] > dot[0]) return 'right';
        if (prev[1] < dot[1]) return 'up';
        if (prev[1] > dot[1]) return 'down';
    }
    return direction;
}

function getTailBorderRadius(dot: TPosition, prev: TPosition) {
    if (prev[0] > dot[0]) {
        return `var(--snake-border-radius) 0 0 var(--snake-border-radius) `;
    }
    if (prev[0] < dot[0]) {
        return `0 var(--snake-border-radius) var(--snake-border-radius) 0`;
    }
    if (prev[1] > dot[1]) {
        return `var(--snake-border-radius) var(--snake-border-radius) 0 0`;
    }
    return `0 0 var(--snake-border-radius) var(--snake-border-radius)`;
}

function getBodyBorderRadius(
    dot: TPosition,
    prev: TPosition | undefined,
    next: TPosition | undefined
) {
    if (!prev || !next) return;
    if ((prev[1] > dot[1] && next[0] < dot[0]) || (prev[0] < dot[0] && next[1] > dot[1])) {
        return `0 var(--snake-border-radius) 0 0`;
    }
    if ((prev[0] > dot[0] && next[1] < dot[1]) || (prev[1] < dot[1] && next[0] > dot[0])) {
        return `0 0 0 var(--snake-border-radius)`;
    }
    if ((prev[1] < dot[1] && next[0] < dot[0]) || (prev[0] < dot[0] && next[1] < dot[1])) {
        return `0 0 var(--snake-border-radius) 0`;
    }
    if ((prev[1] > dot[1] && next[0] > dot[0]) || (prev[0] > dot[0] && next[1] > dot[1])) {
        return `var(--snake-border-radius) 0 0 0`;
    }
}

function getHeadBorderRadius(direction: TSnakeDirection) {
    if (direction === 'right') {
        return `0 var(--snake-border-radius) var(--snake-border-radius) 0`;
    }
    if (direction === 'left') {
        return `var(--snake-border-radius) 0 0 var(--snake-border-radius)`;
    }
    if (direction === 'up') {
        return `var(--snake-border-radius) var(--snake-border-radius) 0 0`;
    }
    return `0 0 var(--snake-border-radius) var(--snake-border-radius)`;
}

export default SnakeCell;
