import React, { useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
    modal,
    container,
    board,
    boardCell,
    game,
    bar,
    pointBox,
    closeButton,
    logoBox,
    scaled,
    gameOuter,
    oddRow,
    evenRow,
    actions,
    summary,
    controlIcon,
    adultIcon,
} from './snake-game.module.scss';
import CloseIcon from '../assets/images/svg/snake-close.svg';
import TouchIcon from '../assets/images/svg/snake-touch.svg';
import KeyboardIcon from '../assets/images/svg/snake-keyboard.svg';
import AdultIcon from '../assets/images/svg/snake-adult.svg';
import { ImageDataLike } from '../models/image-data-like.model';
import { BOTTLE_ICONS, CELL_COUNT_X, CELL_COUNT_Y, CELL_SIZE, TOUCH_EVENTS } from './consts';
import { useSnakeEngine } from './use-snake-engine';
import { getCellString } from './utils';

import SnakeCell from './snake-cell';
import FoodCell from './food-cell';
import GameSummary from './game-summary';

interface ISnakeGameQueryResult {
    bottlesImg: ImageDataLike;
    gramophoneImg: ImageDataLike;
    logoImg: ImageDataLike;
}

export interface ISnakeGameProps {
    onClose?(): void;
    onQuestion?(points: number): void;
}

const SnakeGame: React.FC<ISnakeGameProps> = ({ onClose, onQuestion }) => {
    const { bottlesImg, gramophoneImg, logoImg } = useStaticQuery<ISnakeGameQueryResult>(query);
    const bottlesImgData = getImage(bottlesImg);
    const gramophoneImgData = getImage(gramophoneImg);
    const logoImgData = getImage(logoImg);
    const isTouchDevice = getIsTouchDevice();

    const [scale, setScale] = useState(1);
    const [isScaled, setIsScaled] = useState(false);

    const modalRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const gameOuterRef = useRef<HTMLDivElement>(null);
    const closeButtonRef = useRef<HTMLButtonElement>(null);

    const {
        boardRef,
        boardCells,
        dotsStringsRef,
        foodRef,
        points,
        snakeModeRef,
        dotsRef,
        prevDirectionRef,
        directionRef,
        speed,
        isGameOver,
        isStarted,
        reset,
    } = useSnakeEngine();

    const handleQuestion = () => {
        if (typeof onQuestion === 'function') {
            onQuestion(points);
        }
    };

    useEffect(() => {
        const containerEl = containerRef.current;
        const closeButtonEl = closeButtonRef.current;
        if (!containerEl) return;
        const handleTouchPropagation = (event: TouchEvent) => {
            if (closeButtonEl?.contains(event.target as Node)) return;
            event.preventDefault();
            event.stopPropagation();
        };
        TOUCH_EVENTS.forEach((eventName) => {
            containerEl.addEventListener(eventName, handleTouchPropagation);
        });
        return () => {
            if (containerEl) {
                TOUCH_EVENTS.forEach((eventName) => {
                    containerEl.removeEventListener(eventName, handleTouchPropagation);
                });
            }
        };
    }, []);

    useEffect(() => {
        const handleScale = () => {
            const modalEl = modalRef.current;
            const gameOuterEl = gameOuterRef.current;
            if (!modalEl || !gameOuterEl) return;
            const spaceX = modalEl.clientWidth;
            const spaceY = modalEl.clientHeight;
            const sizeX = gameOuterEl.clientWidth;
            const sizeY = gameOuterEl.clientHeight;
            if (sizeX < spaceX && sizeY < spaceY) {
                setScale(1);
            } else {
                const scaleX = spaceX / sizeX;
                const scaleY = spaceY / sizeY;
                const scale = Math.min(scaleX, scaleY);
                setScale(scale);
            }
            if (!isScaled) {
                setIsScaled(true);
            }
        };
        handleScale();
        window.addEventListener('resize', handleScale);
        return () => {
            window.removeEventListener('resize', handleScale);
        };
    }, [isScaled]);

    return (
        <>
            <div ref={modalRef} className={modal}>
                <div
                    ref={containerRef}
                    className={`${container} ${isScaled ? scaled : ''}`}
                    style={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        '--cell-size': `${CELL_SIZE}px`,
                        '--cell-count-x': `${CELL_COUNT_X}`,
                        '--cell-count-y': `${CELL_COUNT_Y}`,
                        '--snake-speed': `${speed}ms`,
                        '--snake-border-radius': `calc(var(--cell-size) / 2)`,
                    }}
                >
                    <div
                        ref={gameOuterRef}
                        className={gameOuter}
                        style={{ transform: `scale(${scale})` }}
                    >
                        <AdultIcon className={adultIcon} />
                        <div className={game}>
                            <div className={bar}>
                                <div>
                                    {bottlesImgData && (
                                        <GatsbyImage image={bottlesImgData} alt="" />
                                    )}
                                </div>
                                <p className={pointBox}>{points}</p>
                                <div className={logoBox}>
                                    {logoImgData && <GatsbyImage image={logoImgData} alt="" />}
                                </div>
                                <div>
                                    {gramophoneImgData && (
                                        <GatsbyImage image={gramophoneImgData} alt="" />
                                    )}
                                </div>
                                <button
                                    ref={closeButtonRef}
                                    className={closeButton}
                                    onClick={onClose}
                                >
                                    <CloseIcon />
                                </button>
                            </div>
                            <div ref={boardRef} className={board}>
                                {boardCells.map((cell, index) => {
                                    const cellString = getCellString(cell);
                                    const isSnake = dotsStringsRef.current.includes(cellString);
                                    const isFood = cellString === getCellString(foodRef.current);
                                    const oddEvenRowClass =
                                        Math.floor(index / CELL_COUNT_X) % 2 > 0 ? oddRow : evenRow;
                                    if (isSnake) {
                                        return (
                                            <SnakeCell
                                                className={`${boardCell} ${oddEvenRowClass}`}
                                                mode={snakeModeRef.current}
                                                dot={cell}
                                                dots={dotsRef.current}
                                                prevDirection={prevDirectionRef.current}
                                                direction={directionRef.current}
                                            />
                                        );
                                    }
                                    if (isFood) {
                                        return (
                                            <FoodCell
                                                key={`cell-${cellString}`}
                                                className={`${boardCell} ${oddEvenRowClass}`}
                                                Bottle={BOTTLE_ICONS[foodRef.current[2]]}
                                            />
                                        );
                                    }
                                    return (
                                        <div
                                            className={`${boardCell} ${oddEvenRowClass}`}
                                            key={`cell-${cellString}`}
                                        />
                                    );
                                })}
                                {!isStarted &&
                                    (isTouchDevice ? (
                                        <TouchIcon className={controlIcon} />
                                    ) : (
                                        <KeyboardIcon className={controlIcon} />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isGameOver && (
                <div className={`${modal} ${actions}`}>
                    <GameSummary
                        className={summary}
                        points={points}
                        onPlay={reset}
                        onQuestion={handleQuestion}
                    />
                </div>
            )}
        </>
    );
};

function getIsTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

const query = graphql`
    query {
        bottlesImg: file(relativePath: { eq: "snake-bottles.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
        gramophoneImg: file(relativePath: { eq: "snake-gramophone.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
        logoImg: file(relativePath: { eq: "snake-logo-white.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
        }
    }
`;

export default SnakeGame;
