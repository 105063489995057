import React from 'react';

import { food, bottle } from './food-cell.module.scss';

interface IFoodCellProps {
    className?: string;
    Bottle: React.SVGFactory;
}

const FoodCell: React.FC<IFoodCellProps> = ({ Bottle, className = '' }) => {
    return (
        <div className={className}>
            <div className={food}>
                <Bottle className={bottle} />
            </div>
        </div>
    );
};

export default FoodCell;
