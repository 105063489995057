import { TPosition, TSnakeDirection } from './snake-game.model';
import BitterIcon from '../assets/images/svg/bitter-bottle.svg';
import BitterMintIcon from '../assets/images/svg/bitter-mint-bottle.svg';
import NobleIcon from '../assets/images/svg/noble-bottle.svg';

export const INITIAL_SNAKE: TPosition[] = [
    [4, 10],
    [5, 10],
    [6, 10],
];
export const CELL_SIZE = 35;
export const CELL_COUNT_X = 12;
export const CELL_COUNT_Y = 21;
export const MAX_POINTS = CELL_COUNT_X * CELL_COUNT_Y - INITIAL_SNAKE.length;
export const INITIAL_SPEED = 350;
export const MAX_SPEED = 100;
export const SPEED_INCREMENT = ((INITIAL_SPEED - MAX_SPEED) / MAX_POINTS) * 1.4;
export const START_RG = [232, 44];
export const END_RG = [255, 132];
export const INITIAL_DIRECTION = 'right';
export const INITIAL_MODE = 'normal';
export const BOTTLE_ICONS: React.SVGFactory[] = [BitterIcon, BitterMintIcon, NobleIcon];
export const TOUCH_EVENTS: (keyof Pick<
    HTMLElementEventMap,
    'touchstart' | 'touchmove' | 'touchend'
>)[] = ['touchstart', 'touchmove', 'touchend'];
export const TOUCH_SENSITIVITY_FACTOR = 15;
export const KEY_TO_DIRECTION: Record<string, TSnakeDirection> = {
    ArrowRight: 'right',
    ArrowLeft: 'left',
    ArrowUp: 'up',
    ArrowDown: 'down',
};
export const NOT_ALLOWED_DIRECTION_CHANGES: `${TSnakeDirection | undefined},${TSnakeDirection}`[] =
    ['up,down', 'down,up', 'left,right', 'right,left'];
