import React, { useEffect, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';
import { StaticImage } from 'gatsby-plugin-image';

import {
    container,
    layout,
    prize,
    form,
    img,
    isExpired,
    banner,
    mobile,
    desktop,
    scoreboard,
    game,
    knight,
    peelLeft,
    peelRight,
    person,
    soplicaGorzka,
    soplicaMint,
    soplicaNoble,
    vinyl,
} from './main.module.scss';

import { IPage } from '../../../models/page.model';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import GrajWKlasykeLayout from '../../../layouts/graj-w-klasyke-layout';
import GrajWKlasykePrize from '../../../components/organisms/graj-w-klasyke-prize';
import GrajWKlasykeForm from '../../../components/organisms/graj-w-klasyke-form';
import GrajWKlasykeScoreboard from '../../../components/organisms/graj-w-klasyke-scoreboard';
import SnakeGame, { ISnakeGameProps } from '../../../snake/snake-game';

interface IGrajWKlasykePageProps {
    readonly data: {
        page: IPage | null;
    };
}

const presentationImg = '../../../assets/images/graj-w-klasyke/presentation.png';
const presentationMobileImg = '../../../assets/images/graj-w-klasyke/presentation-mobile.png';
const gameImg = '../../../assets/images/graj-w-klasyke/game.png';
const knightImg = '../../../assets/images/graj-w-klasyke/knight.png';
const peelLeftImg = '../../../assets/images/graj-w-klasyke/peel-left.png';
const peelRightImg = '../../../assets/images/graj-w-klasyke/peel-right.png';
const personImg = '../../../assets/images/graj-w-klasyke/person.png';
const soplicaGorzkaImg = '../../../assets/images/graj-w-klasyke/soplica-gorzka.png';
const soplicaMintImg = '../../../assets/images/graj-w-klasyke/soplica-mint.png';
const soplicaNobleImg = '../../../assets/images/graj-w-klasyke/soplica-noble.png';
const vinylImg = '../../../assets/images/graj-w-klasyke/vinyl.png';

const Main: React.FC<IGrajWKlasykePageProps> = ({ data }) => {
    const IS_COMPETITION_EXPIRED = false;
    const { t } = useI18next();
    const { page } = data;
    const containerRef = useRef<HTMLDivElement>(null);
    const answerRef = useRef<HTMLParagraphElement>(null);
    const [isSnakeGameOpen, setIsSnakeGameOpen] = useState(false);
    const [snakePoints, setSnakePoints] = useState<number>(0);

    const bannerOnClick = () => {
        containerRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    };

    const queryClient = new QueryClient();

    const handleOpenSnakeGame = () => setIsSnakeGameOpen(!isSnakeGameOpen);

    const handleQuestion: ISnakeGameProps['onQuestion'] = (points) => {
        handleOpenSnakeGame();
        setSnakePoints(points);
        answerRef.current?.scrollIntoView({ block: 'start' });
    };

    useEffect(() => {
        function preventDefault(e: Event) {
            e.preventDefault();
        }

        if (isSnakeGameOpen) {
            window.addEventListener('keyup', preventDefault);
            window.addEventListener('keydown', preventDefault);
        }

        return () => {
            window.removeEventListener('keyup', preventDefault);
            window.removeEventListener('keydown', preventDefault);
        };
    }, [isSnakeGameOpen]);

    return (
        <QueryClientProvider client={queryClient}>
            <GrajWKlasykeLayout
                page={page}
                className={container}
                layoutClass={`${layout} ${IS_COMPETITION_EXPIRED ? isExpired : ''}`}
                bannerOnClick={bannerOnClick}
                bannerClass={`${banner} ${IS_COMPETITION_EXPIRED ? isExpired : ''}`}
                headerProps={
                    IS_COMPETITION_EXPIRED
                        ? {
                              titleFirstLine: t('graj.w.klasyke.closed.title.1'),
                              titleSecondLine: t('graj.w.klasyke.closed.title.2'),
                          }
                        : undefined
                }
                isMainPage={true}
            >
                {!IS_COMPETITION_EXPIRED && (
                    <>
                        <div className={prize}>
                            <GrajWKlasykePrize />
                        </div>
                        <div className={`${img} ${desktop}`}>
                            <StaticImage src={presentationImg} alt="" />
                        </div>
                        <div className={`${img} ${mobile}`}>
                            <StaticImage src={presentationMobileImg} alt="" />
                        </div>
                        <div ref={containerRef} className={form}>
                            <GrajWKlasykeForm
                                snakePoints={snakePoints}
                                handleOpenSnakeGame={handleOpenSnakeGame}
                                answerRef={answerRef}
                            />
                            <StaticImage className={game} src={gameImg} alt="" />
                            <StaticImage className={knight} src={knightImg} alt="" />
                            <StaticImage className={peelLeft} src={peelLeftImg} alt="" />
                            <StaticImage className={peelRight} src={peelRightImg} alt="" />
                            <StaticImage className={person} src={personImg} alt="" />
                            <StaticImage className={soplicaGorzka} src={soplicaGorzkaImg} alt="" />
                            <StaticImage className={soplicaMint} src={soplicaMintImg} alt="" />
                            <StaticImage className={soplicaNoble} src={soplicaNobleImg} alt="" />
                            <StaticImage className={vinyl} src={vinylImg} alt="" />
                        </div>
                        <div className={scoreboard}>
                            <GrajWKlasykeScoreboard />
                        </div>
                    </>
                )}
            </GrajWKlasykeLayout>
            {isSnakeGameOpen && (
                <SnakeGame onClose={handleOpenSnakeGame} onQuestion={handleQuestion} />
            )}
        </QueryClientProvider>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Main;
