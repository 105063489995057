import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import {
    container,
    title,
    button,
    open,
    list,
    top,
    item,
    innerList,
    name,
    empty,
} from './graj-w-klasyke-scoreboard.module.scss';

import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { useQuery } from 'react-query';
import { getGrajWKlasykeScoreboard } from '../../api-ssr/graj-w-klasyke';

import Button from '../atoms/button';

const GrajWKlasykeScoreboard = () => {
    const { t } = useI18next();
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState<number | 'auto'>(0);

    const { isError, data, isSuccess } = useQuery('scoreboard', () => getGrajWKlasykeScoreboard());

    const handleClick = () => {
        setHeight(height === 0 ? 'auto' : 0);
        setIsOpen(!isOpen);
    };

    return (
        <div className={`${container} ${isOpen ? open : ''}`}>
            <p className={title}>{t('graj.w.klasyke.form.leaderboard')}</p>
            <div>
                <AnimateHeight className={list} height={height} duration={300}>
                    {isError && <p>{t('graj.w.klasyke.form.leaderboard.error')}</p>}
                    {isSuccess && data.data && data.data.length === 0 && (
                        <p className={empty}>{t('graj.w.klasyke.form.leaderboard.empty')}</p>
                    )}
                    {isSuccess && data.data && data.data.length > 0 && (
                        <>
                            <p className={top}>TOP 20</p>
                            <ul className={innerList}>
                                {data.data.map((score, i) => {
                                    return (
                                        <li className={item} key={`score-${i}`}>
                                            <span className={name}>
                                                {i + 1}. {score.nick}
                                            </span>{' '}
                                            <span>{score.score}</span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                </AnimateHeight>
            </div>
            <Button className={button} onClick={handleClick}>
                {isOpen ? t('graj.w.klasyke.form.close') : t('graj.w.klasyke.form.check')}
            </Button>
        </div>
    );
};

export default GrajWKlasykeScoreboard;
